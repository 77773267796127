import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import {
    Subscribers,
    Subscriber,
    GetSubscribersByGroupSetIdRequest,
    CreateSubscriberRequest,
    UpdateSubscriberRequest,
} from '../apiCommandsExecutor/api';
import { execute } from '../apiCommandsExecutor';
import rootStore from './index';
import { naturalTextComparator } from '../common/sortLibs';

class SubscribersStore {
    subscribers: Subscriber[] = [];
    currentSubscriber: Subscriber;
    currentSubscriberForMove: Subscriber;
    currentSubscriberForRemove: Subscriber;
    modalState = false;
    removeModal = false;
    moveModal = false;

    constructor() {
        makeAutoObservable(this);
    }

    async getSubscribersByGroupSetId(
        params: GetSubscribersByGroupSetIdRequest,
    ) {
        const response = await execute<Subscribers>(
            `subscribers?skip=${params.skip}&limit=${params.limit}&group_set_id=${params.group_set_id}`,
            {
                method: 'GET',
            },
        );
        if (response) {
            runInAction(() => {
                this.subscribers = response.subscribers.sort((a, b) => naturalTextComparator(a.username, b.username));
            });
        } else {
            return;
        }
    }

    async createSubscriber(params: CreateSubscriberRequest, clean: () => void) {
        const response = await execute<Subscriber>(`subscribers`, {
            method: 'POST',
            data: {
                ...params,
            },
        });
        if (!response) {
            rootStore.generalStore.showSnackbar(
                'Не удалось создать SIP-аккаунт',
            );
        } else {
            rootStore.generalStore.showSnackbar(
                `Аккаунт ${response.username} создан`,
            );
            await this.getSubscribersByGroupSetId({
                limit: 400,
                skip: 0,
                group_set_id: rootStore.generalStore.currentGroupSetId,
            });
            clean();
            this.closeModal();
        }
    }

    async updateSubscriber(params: UpdateSubscriberRequest, clean: () => void) {
        const response = await execute<Subscriber>(
            `subscribers/${this.currentSubscriber.id}`,
            {
                method: 'PUT',
                data: {
                    ...params,
                },
            },
        );
        if (!response) {
            rootStore.generalStore.showSnackbar(
                'Не удалось обновить SIP-аккаунт',
            );
        } else {
            rootStore.generalStore.showSnackbar(
                `Аккаунт ${response.username} успешно обновлён`,
            );
            await this.getSubscribersByGroupSetId({
                limit: 400,
                skip: 0,
                group_set_id: rootStore.generalStore.currentGroupSetId,
            });
            clean();
            this.closeModal();
        }
    }

    async moveSubscriber(params: UpdateSubscriberRequest, clean: () => void) {
        const response = await execute<Subscriber>(
            `subscribers/${this.currentSubscriberForMove.id}`,
            {
                method: 'PUT',
                data: {
                    ...params,
                },
            },
        );
        if (
            params.group_id === this.currentSubscriberForMove.group_id &&
            params.group_set_id === this.currentSubscriberForMove.group_set_id
        ) {
            rootStore.generalStore.showSnackbar(
                'Этот аккаунт уже привязан к выбранной квартире',
            );
            return;
        }
        if (!response) {
            rootStore.generalStore.showSnackbar(
                'Не удалось перенести SIP-аккаунт',
            );
        } else {
            rootStore.generalStore.showSnackbar(
                `Аккаунт ${response.username} успешно перенесён`,
            );
            await this.getSubscribersByGroupSetId({
                limit: 400,
                skip: 0,
                group_set_id: rootStore.generalStore.currentGroupSetId,
            });
            clean();
            this.closeMoveModal();
        }
    }

    async removeSubscriber() {
        const response = await execute<boolean>(
            `subscribers/${this.currentSubscriberForRemove.id}`,
            {
                method: 'DELETE',
            },
        );
        if (!response) {
            rootStore.generalStore.showSnackbar(
                'Не удалось удалить SIP-аккаунт',
            );
        } else {
            this.closeRemoveModal();
            rootStore.generalStore.showSnackbar(
                'SIP-аккаунт удалён из системы',
            );
            await this.getSubscribersByGroupSetId({
                limit: 400,
                skip: 0,
                group_set_id: rootStore.generalStore.currentGroupSetId,
            });
        }
    }

    @computed
    filterSubscribersByGroup(id: number) {
        return this.subscribers.filter((i) => i.group_id === id);
    }

    @action
    setCurrent(subscriber: Subscriber) {
        this.currentSubscriber = subscriber;
    }

    @action
    setCurrentForMove(subscriber: Subscriber) {
        this.currentSubscriberForMove = subscriber;
    }

    @action
    setCurrentForRemove(subscriber: Subscriber) {
        this.currentSubscriberForRemove = subscriber;
    }

    @action
    openModal(subscriber?: Subscriber) {
        subscriber && this.setCurrent(subscriber);
        this.modalState = true;
    }

    @action
    closeModal() {
        this.currentSubscriber = undefined;
        this.modalState = false;
    }

    @action
    openMoveModal(subscriber: Subscriber) {
        this.setCurrentForMove(subscriber);
        this.moveModal = true;
    }

    @action
    closeMoveModal() {
        this.currentSubscriberForMove = undefined;
        this.moveModal = false;
    }

    @action
    openRemoveModal(subscriber: Subscriber) {
        this.setCurrentForRemove(subscriber);
        this.removeModal = true;
    }

    @action
    closeRemoveModal() {
        this.currentSubscriberForRemove = undefined;
        this.removeModal = false;
    }
}

export default SubscribersStore;
